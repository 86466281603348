/* Import variables if needed */
@import "../../../scss/variables";

/* Existing styles */
.mainContainer {
  display: flex;
  flex: 1;
  height: 100%;
  padding: 10px 5px;
}

.errorMessage {
  color: red;
  font-size: 0.7rem;
  margin: 5px 0px;
}

.formContainer {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  height: max-content;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
  box-sizing: border-box;
}

.formGroup {
  margin-bottom: 15px;
}

.formGroup label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 1rem;
}

.formGroup input,
.formGroup textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  box-sizing: border-box;
}

.formGroup input:focus,
.formGroup textarea:focus {
  border-color: $primary;
  outline: none;
}

.formGroup textarea {
  resize: vertical;
  min-height: 100px; /* Adjust based on your preference */
}

.submitButton {
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  background-color: $primary;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submitButton:hover {
  background-color: $primary;
}

/* Responsive design */
@media (max-width: 768px) {
  .formContainer {
    padding: 15px;
  }

  .formGroup label {
    font-size: 0.875rem;
  }

  .formGroup input,
  .formGroup textarea {
    font-size: 0.875rem;
  }

  .submitButton {
    font-size: 0.875rem;
    padding: 8px 12px;
  }
}

@media (max-width: 480px) {
  .formContainer {
    padding: 10px;
  }

  .formGroup label {
    font-size: 0.75rem;
  }

  .formGroup input,
  .formGroup textarea {
    font-size: 0.75rem;
  }

  .submitButton {
    font-size: 0.9rem;
    font-weight: 500;
    padding: 5px 10px;
    height: 35px;
  }
}

/* Simple radio button styles */
.toggleGroup {
  display: flex;
  flex-direction: column;
  margin-top: 15px; /* Space between the toggle group and other form elements */
}

.radioLabel {
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 1rem;
}

.radioLabel input[type="radio"] {
  margin-right: 10px;
  display: none;
  accent-color: $primary; /* Sets the color of the radio button */
}

.radioLabel span {
  display: inline-block;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 2px solid $primary;
  background-color: #fff;
  position: relative;
  top: 5px;
  margin: 0px 5px 0px 0px;
}

.radioLabel input[type="radio"]:checked + span {
  background-color: $primary;
}

.radioLabel input[type="radio"]:checked + span::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: white;
  transform: translate(-50%, -50%);
}
.imageUploadContainer {
  width: 85%;
  max-width: 85%;
  text-align: center;
  margin-bottom: 10px;
  h2 {
    font-size: 12px;
    text-align: left;
    margin: 0px;
    margin-bottom: 5px;
    color: black;
  }
}

.uploadBtn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 7px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
}

.fileInput {
  margin-bottom: 10px;
  display: block;
  margin: 0 auto;
}
.previewImage {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  img {
    height: 50px;
    object-fit: contain;
  }
}
