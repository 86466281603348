.formContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.form {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  max-width: 100%; /* Adjust for mobile */
  width: 100%; /* Full width for mobile */
  box-sizing: border-box; /* Ensure padding doesn't break layout */
}

.formGroup {
  margin-bottom: 15px;
}

label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

input[type="text"],
input[type="file"] {
  width: 100%; /* Full width */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  box-sizing: border-box; /* Ensure padding doesn't exceed container */
}

.radioGroup {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

input[type="radio"] {
  margin-right: 5px;
}

.error {
  color: red;
  font-size: 12px;
}

.submitButton {
  width: 100%; /* Full width for better mobile UX */
  padding: 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.submitButton:hover {
  background-color: #45a049;
}

/* Mobile responsiveness */
@media (max-width: 600px) {
  .form {
    padding: 15px;
  }

  .formGroup {
    margin-bottom: 12px;
  }

  .radioGroup {
    flex-direction: column;
    gap: 5px;
  }

  input[type="text"],
  input[type="file"] {
    font-size: 14px;
    padding: 8px;
  }

  .submitButton {
    font-size: 14px;
    padding: 8px;
  }
}
