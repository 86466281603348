.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4); // Slightly darker overlay
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; // Ensure the modal is above other content
}

.modalContainer {
  background: #fff;
  border-radius: 8px;
  max-width: 500px;
  width: 90%;
  padding: 20px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
  position: relative;
}

.modalHeader {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.modalIcon {
  margin-right: 10px;
  font-size: 24px;
  color: #d9534f; // Match icon color with confirm button
}

.modalBody {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.5;
}

.modalFooter {
  display: flex;
  justify-content: flex-end;
  gap: 10px; // Space between buttons
}

.cancelButton,
.confirmButton {
  border: none;
  border-radius: 4px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cancelButton {
  background: #f0f0f0;
  color: #333;
}

.confirmButton {
  background: #d9534f;
  color: #fff;
}

.cancelButton:hover {
  background: #e0e0e0;
}

.confirmButton:hover {
  background: #c9302c;
}

@media (max-width: 768px) {
  .modalContainer {
    width: 95%;
    padding: 15px;
  }

  .modalHeader h2 {
    font-size: 20px;
  }

  .modalBody p {
    font-size: 14px;
  }

  .cancelButton,
  .confirmButton {
    font-size: 14px;
    padding: 8px 16px;
  }
}

@media (max-width: 480px) {
  .modalContainer {
    width: 100%;
    padding: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 10px;
  }

  .modalHeader {
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 0px;
  }

  .modalIcon {
    margin: 0;
    font-size: 50px;
  }

  .modalFooter {
    flex-direction: row;
    width: 80%;
  }

  .cancelButton,
  .confirmButton {
    font-size: 12px;
    width: 100%;
    height: 30px;
  }

  .cancelButton {
    margin-bottom: 10px;
  }
}
