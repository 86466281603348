@import "../../scss/variables";
.nav {
  height: 9vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  background-color: transparent;
  z-index: 10;
  background: linear-gradient(30deg, #fbc148, #fb8a46);
  border-radius: 0px 0px 0px 50px;
  .titleContainer {
    margin-left: 50px;
    display: flex;
    align-items: center;
    img {
      height: 35px;
      width: 35px;
      border-radius: 17.5px;
    }
  }
  .navsContainer {
    width: 40%;
    margin-right: 50px;
    .activeTab {
      color: rgb(1, 240, 255);
      &::before {
        content: "";
        width: 100%;
        border-top: 2px solid transparent;
      }
      &::after {
        content: "";
        width: 100%;
        border-bottom: 2px solid rgb(1, 240, 255);
      }
    }
    .menu {
      display: none;
      color: white;
      font-size: 2rem;
    }
    ul {
      display: flex;
      justify-content: space-around;
      align-items: center;
      li {
        list-style: none;
        font-size: 1rem;
        color: white;
        cursor: pointer;
        font-weight: 600;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        transition: all ease-in-out 200ms;
        &:hover {
          color: rgb(1, 240, 255);
          &::before {
            content: "";
            width: 100%;
            border-top: 2px solid transparent;
          }
          &::after {
            content: "";
            width: 100%;
            border-bottom: 2px solid rgb(1, 240, 255);
          }
        }
      }
    }

    .modeBtn {
      width: 60px;
      border-radius: 12.5px;
      background-color: rgb(0, 91, 98);
      height: 25px;
      padding: 1.5px 2.5px;
      position: relative;
      .changeBtn {
        height: 25px;
        width: 25px;
        background-color: black;
        border-radius: 12.5px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        position: absolute;
        transition: all ease-in-out 400ms;
        .icon {
          color: rgb(1, 240, 255);
        }
      }
      .toggled {
        transform: translateX(35px);
      }
    }
  }
}
.footer {
  text-align: center;
  color: white;
  padding: 20px 0px 20px 0px;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  .icon {
    margin-left: 5px;
    color: red;
    font-size: 1rem;
  }
}
@media only screen and (max-width: 1100px) {
  .nav {
    .navsContainer {
      width: 60%;

      .modeBtn {
        width: 50px;
        border-radius: 10px;
        height: 20px;
        .changeBtn {
          height: 20px;
          width: 20px;
          border-radius: 10px;
        }
        .toggled {
          transform: translateX(30px);
        }
      }
    }
  }
}
.footer {
  padding: 15px 0px;
  font-size: 0.8rem;
  color: white;
  .icon {
    font-size: 0.8rem;
  }
}
@media only screen and (max-width: 720px) {
  .nav {
    .titleContainer {
      margin-left: 25px;
      span {
        font-size: 1.5rem;
      }
    }
    .navsContainer {
      display: flex;
      margin-right: 0px;
      width: 50%;
      justify-content: space-between;
      .activeTab {
        background-color: rgb(1, 240, 255);
        color: rgb(6, 18, 42);
        font-weight: 600;
        &:hover {
          color: rgb(6, 18, 42);
        }
      }
      .openMenu {
        display: block;
        padding-top: 20px;
      }
      .closeMenu {
        display: none;
      }

      .modeBtn {
        width: 50px;
        border-radius: 10px;
        height: 20px;
        margin: 10px 0px;
        left: 20px;
        .changeBtn {
          height: 20px;
          width: 20px;
          border-radius: 10px;
        }
        .toggled {
          transform: translateX(30px);
        }
      }
      ul {
        position: absolute;
        flex-direction: column;
        align-items: flex-start;
        background-color: rgb(6, 18, 42);
        color: rgb(1, 240, 255);
        right: 0;
        width: 100%;
        top: -2.5vh;
        li {
          font-size: 0.8rem;
          font-weight: 400;
          padding: 10px 10px 10px 25px;
          &::before {
            content: "";
            border: 0 !important;
          }
          &::after {
            content: "";
            border: 0 !important;
          }
        }
      }
      .menu {
        display: block;
      }
    }
  }
  .footer {
    padding: 10px 0px;
    font-size: 0.7rem;
    .icon {
      font-size: 0.7rem;
    }
  }
}
.navLight {
  .titleContainer {
    span {
      color: rgb(20, 33, 61);
    }
  }
  .navsContainer {
    .activeTab {
      color: $white;
      max-width: 100%;
      background-color: #fb9746;
      &::after {
        border-bottom: 2px solid $primary;
      }
    }
    .menu {
      z-index: 100000;
      font-size: 30px;
      margin-right: 10px;
    }
    ul {
      background-color: rgb(229, 229, 229);
      li {
        color: rgb(0, 0, 0);
        &:hover {
          color: $primary;
          &::after {
            border-bottom: 2px solid $primary;
          }
        }
      }
    }

    .modeBtn {
      background-color: rgb(252, 163, 17, 0.3);
      .changeBtn {
        background-color: rgb(252, 163, 17);
        .icon {
          color: rgb(20, 33, 61);
        }
      }
    }
  }
}
.footerLight {
  color: black;
  font-weight: 600;
  background-color: rgba(0, 0, 0, 0.3);
  .icon {
    color: red;
  }
}
.logo {
  height: 36px;
  width: 36px;
  align-self: center;
  border-radius: 18px;
  object-fit: cover;
}
.extraStyle {
  top: 5px;
  color: white;
  background-color: rgb(129, 128, 128);
  border-radius: 50%;
}
.channelIdBox {
  margin: 0px 20px;
  display: flex;
  justify-content: space-between;
  input {
    padding: 5px;
    font-size: 12px;
    border-radius: 5px;
    width: 70%;
  }
  .btn {
    font-size: 12px;
    padding: 5px 10px;
  }
}
