// src/components/LottieAnimation.module.scss

.lottieContainer {
  display: flex;
  justify-content: center; /* Center horizontally */
  align-items: center;
}

@media (max-width: 600px) {
  .lottieContainer {
    align-items: center;
    justify-content: center;
    height: 70vh;
  }
}
