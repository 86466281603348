@import "../scss/variables";

/* Base styles */
.page {
  max-width: 1920px;
  margin: 0 auto;
  background-color: #091c2a;
}

.pageLight {
  background-color: rgb(255, 255, 255);
  transition: all ease-in-out 1000ms !important;
}

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

/* Header */
.header {
  background-color: #007bff;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px;
}

/* Main content */
.mainContent {
  flex: 1;
  padding: 20px;
  text-align: center;
}

/* Footer */
.footer {
  background-color: #f1f1f1;
  text-align: center;
  padding: 10px;
  font-size: 14px;
}

/* Media query for mobile devices */
@media (min-width: 480px) {
  .container {
    display: block; /* Hide content for larger screens */
  }
  .page {
    display: none;
  }
}
@media (max-width: 480px) {
  .container {
    display: none; /* Hide content for larger screens */
  }
  .page {
    display: block;
  }
}
