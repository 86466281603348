.container {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  .outlet {
    display: flex;
    flex: 1;
    flex-direction: column;
    padding: 5px 10px;
  }
}
