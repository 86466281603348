.imageUploadContainer {
  background-color: #f8f9fa;
  padding: 20px;
  border-radius: 10px;
  width: 90%;
  max-width: 600px;
  margin: 20px auto;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.uploadBtn {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 7px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  margin-top: 20px;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #0056b3;
  }
}

.fileInput {
  margin-bottom: 10px;
  display: block;
  margin: 0 auto;
}

.progressBar {
  margin-top: 5px;
  width: 100%;
}

.previewContainer {
  margin-top: 15px;
  text-align: left;

  p {
    margin: 5px 0;
    font-size: 14px;
    color: #333;
  }
}

.imagePreview {
  margin-top: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  img {
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 5px;
  }
}
.disabled {
  background-color: gray;
}
