.galleryContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
  background-color: #f1f3f5;
  margin-top: 10px;
}

.imageCard {
  margin: 10px;
  padding: 15px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }

  img {
    max-width: 100%;
    border-radius: 8px;
    object-fit: cover;
    height: auto;
  }
}

/* Media Queries for Mobile Devices */
@media (max-width: 768px) {
  .galleryContainer {
    align-items: center;
  }

  .imageCard {
    width: 100%;
    max-width: 350px;
    margin: 0px;
    padding: 10px;
    margin: 5px 0px;
  }
}
