@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

$primary-color: #4caf50;
$secondary-color: #f9f9f9;
$dark-gray: #333;
$light-gray: #ddd;
$border-radius: 8px;

.container {
  max-width: 1200px;
  margin: 0 0;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}
.carouselItem {
  height: 200px;
  margin: 5px;
  padding: 10px;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  overflow: hidden;
  img {
    object-fit: cover;
    border-radius: 5px;
    height: 180px;
  }
}
.loaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.Carousel {
  border-radius: 20px;
}
.summarySection {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  margin-top: 20px;
  .card {
    flex: 1;
    background: linear-gradient(0deg, #ffffff 53%, rgb(251, 151, 71) 00%);
    border: 1px solid $light-gray;
    border-radius: $border-radius;
    padding: 10px 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
    position: relative;

    .icon {
      font-size: 20px;
      color: $primary-color;
      margin-top: 5px;
      padding: 10px;
      border-radius: 50%;
      background-color: white;
    }

    h2 {
      font-size: 15px;
      margin: 5px 0px;
      color: $dark-gray;
    }

    p {
      font-size: 12px;
      color: $dark-gray;
      font-weight: 600;
    }

    &:hover {
      transform: translateY(-5px);
      box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
    }
  }
}

.amountLeftSection {
  background: linear-gradient(0deg, #ffffff 53%, rgb(251, 151, 71) 00%);
  border: 1px solid $light-gray;
  border-radius: $border-radius;
  padding: 10px 20px;
  margin-top: 10px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  .icon {
    font-size: 20px;
    color: $primary-color;
    margin-top: 5px;
    padding: 10px;
    border-radius: 50%;
    background-color: white;
  }

  h2 {
    font-size: 15px;
    margin: 5px 0px;
    color: $dark-gray;
  }

  p {
    font-size: 12px;
    color: $dark-gray;
    font-weight: 600;
  }
}
.donorsSection {
  margin-bottom: 30px;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

  h2 {
    font-size: 13px;
    margin-bottom: 15px;
    color: $dark-gray;
    font-weight: bold;
    letter-spacing: 0.05em;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      padding: 12px;
      margin-bottom: 10px;
      border-bottom: 1px solid $light-gray;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &:last-child {
        border-bottom: none;
      }

      span {
        font-weight: 500;
        color: black;
        font-size: 11px;
      }

      strong {
        color: $primary-color;
        font-size: 11px;
      }
    }
  }
}

.eventsSection {
  margin: 20px 10px 10px 10px;
  h2 {
    font-size: 1.1rem;
    margin-bottom: 10px;
    color: $dark-gray;
  }

  .carousel .slide img {
    width: 100%;
    height: auto;
    border-radius: $border-radius;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }

  .carousel .control-arrow {
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    height: 40px;
    width: 40px;
    line-height: 40px;
    font-size: 24px;
    color: #fff;
  }

  .carousel .control-dots .dot {
    background-color: $light-gray;
    border: none;
  }

  .carousel .control-dots .dot.selected {
    background-color: $dark-gray;
  }
}

// @media (max-width: 768px) {
//   .summarySection {
//     flex-direction: column;
//     gap: 10px;
//   }

//   .card {
//     font-size: 0.9rem;
//   }
// }
// .donarCard {
//   background: linear-gradient(
//     180deg,
//     #ffffff 80%,
//     rgb(1, 206, 209) 100%
//   ) !important;
// }
.mainTop {
  display: flex;
  justify-content: space-between;
  margin: 10px 0px;
  .itemCard {
    height: 80px;
    width: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    .logoCard {
      background-color: rgb(245, 245, 253);
      padding: 10px 12px;
      border-radius: 30%;
      .logo {
        font-size: 25px;
      }
    }
    .itemTitle {
      font-size: 11px;
      font-weight: 600;
    }
  }
}

.eventSection {
  display: flex;
  flex-direction: column;
  img {
    margin: 10px 0px;
    border-radius: 10px;
    box-shadow: 0px 0px 2px 0px gray;
  }
}
