@import "../../scss/variables";

.button {
  background-color: $primary;
  cursor: pointer;
  font-size: 13px;
  border-radius: 5px;
  color: $white;
  padding: 5px 30px;
}
.radio {
  padding: 15px 0;
  border: 1px solid lightgrey;
  cursor: pointer;
  border-radius: 1rem;
  display: flex;
  margin: 10px 0px;
  align-items: center;
  justify-content: space-around;
}
.radioButton {
  width: 50%;
  margin: 0px 30px;
}
.radioLabelStyle {
  color: black;
  font-size: 1rem;
  margin-left: 10px;
}
