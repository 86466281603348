.members-page {
  padding: 20px;
  text-align: center;
  font-family: "Arial", sans-serif;
  background-color: #f9f9f9;
}

h1 {
  font-size: 2.5rem;
  color: #222;
  margin-bottom: 30px;
}
.ceo-managers {
  margin-top: 10px;
}
.ceo-managers h2,
.common-people h2 {
  margin-top: 30px;
  font-size: 2rem;
  color: #333;
}

.member-cards {
  display: flex;
  flex-direction: column;
  gap: 15px;
  justify-items: center;
  width: 100%;
}

.member-card {
  padding: 15px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  width: 150px;
}

.member-card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15);
}

.member-card img {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  margin-bottom: 10px;
}

.member-card h3 {
  margin: 5px 0;
  font-size: 1.25rem;
  color: #444;
}

.member-card p {
  font-size: 0.9rem;
  color: #777;
}

.ceo-manager {
  background-color: #e6f7ff;
}

@media (max-width: 768px) {
  .members-page {
    padding: 10px;
    background-color: transparent;
  }
  h1 {
    font-size: 19px;
    color: #222;
    margin-bottom: 10px;
    text-align: left;
  }

  h2 {
    font-size: 1.5rem;
  }

  .member-cards {
    grid-template-columns: repeat(4, 1fr);
  }

  .member-card {
    max-width: 120px;
    padding: 10px;
  }

  .member-card img {
    width: 60px;
    height: 60px;
  }

  .member-card h3 {
    font-size: 1rem;
  }

  .member-card p {
    font-size: 0.75rem;
  }
}

@media (max-width: 480px) {
  .member-cards {
    grid-template-columns: repeat(2, 1fr);
  }

  .member-card {
    width: 100%;
    padding: 20px 8px;
    min-width: 95%;
    background: linear-gradient(120deg, #fbc148, #fb8a46);
  }

  .member-card img {
    width: 60px;
    height: 60px;
    object-fit: cover;
    object-position: top;
  }

  .member-card h3 {
    font-size: 0.9rem;
    color: white;
  }

  .member-card p {
    font-size: 0.7rem;
    color: black;
  }
  .member-card-other-container {
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 15px;
    .member-card-other {
      width: 42% !important;
      min-width: 42%;
      background-color: white;
      background: none;
      img {
        height: 50px;
        width: 50px;
        margin-bottom: 5px;
      }
      h3 {
        font-size: 12px;
        color: black;
      }
      p {
        font-size: 10px;
        color: gray;
      }
    }
  }
  .ceo-manager {
    img {
      border-radius: 50%;
      width: 100px;
      height: 100px;
      object-fit: cover;
      object-position: top;
    }
    h3 {
      font-size: 19px;
    }
    p {
      font-size: 14px;
    }
  }
  /* Developer Section Styling */
  .developer-section {
    margin-top: 20px;
    padding: 20px;
    background-color: #f1f1f1;
    border-radius: 8px;
    text-align: center;
    h2 {
      font-size: 19px;
    }
  }

  .developer-card {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .developer-photo {
    border-radius: 50%;
    width: 100px;
    height: 100px;
    object-fit: cover;
  }

  .developer-info {
    margin-top: 5px;
    h3 {
      font-size: 18px;
    }
    p {
      width: 100%;
      font-size: 14px;
    }
  }

  .developer-link {
    color: #1976d2;
    text-decoration: none;
  }

  .developer-link:hover {
    text-decoration: underline;
  }
}
.loaderContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 380px) {
  .member-card-other-container {
    .member-card-other {
      width: 38% !important;
      min-width: 38%;
      background-color: white;
      background: none;
      img {
        height: 45px;
        width: 45px;
        margin-bottom: 5px;
      }
    }
  }
}
