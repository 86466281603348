@import "../../scss/variables";
.container {
  display: flex;
  flex-direction: column;
  flex: 1;
}
.headerContainer {
  display: flex;
  margin: 10px 0px 10px 0px;
  justify-content: space-between;
  h1 {
    margin: 0;
  }
}
.addBtn {
  padding: 5px 25px;
  height: 25px;
  background-color: $primary;
  color: white;
  border: 0;
  border-radius: 5px;
  font-size: 12px;
  font-weight: 600;
}
.exportBtn {
  padding: 5px 15px;
  height: 25px;
  background-color: $btnGreen;
  color: white;
  border: 0;
  border-radius: 5px;
  display: flex;
  width: max-content;
  align-self: flex-end;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  .icon {
    margin-left: 5px;
  }
}
.inputContainer {
  display: flex;
  justify-content: space-between;
  .searchInput {
    padding: 5px 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 12px;
    width: 80%;
  }
  .dateInput {
    padding: 5px 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
    width: 5%;
    margin-left: 5px;
  }
  .dateInput::-webkit-datetime-edit-fields-wrapper {
    visibility: hidden;
  }
}
.listContainer {
  list-style: none;
  padding: 0;
  margin-top: 0px;
}

.listItem {
  position: relative; /* Ensure that the indicator is positioned relative to the card */
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  background-color: #f9f9f9;
  overflow: hidden; /* Ensure the ellipsis appears correctly */
  display: flex;
  flex-direction: column;
}

.pendingIndicator {
  position: absolute;
  top: 10px;
  border-radius: 10px;
  font-size: 9px;
  right: 5px;
  padding: 4px 7px;
  background-color: rgb(0, 153, 164);
  color: white;
}
.pendingCont {
  display: flex;
  align-items: center;
  span {
    font-size: 13px;
    font-weight: 500;
    margin-left: 2px;
  }
}
.paid {
  position: absolute;

  border-radius: 10px;
  font-size: 9px;
  right: 5px;
  padding: 4px 7px;
  background-color: rgb(45, 192, 0);
  color: white;
}
.cardContent {
  width: 100%;
}

.name {
  font-size: 1rem;
  font-weight: 600;
  max-width: 90%;
  word-wrap: break-word; /* Ensure the name wraps if too long */
}

.details {
  display: flex;
  justify-content: space-between;
  align-items: center; /* Center align items vertically */
}
.date {
  font-size: 0.7rem;
  font-weight: 500;
  color: grey;
  span {
    color: black;
  }
}
.address {
  span {
    color: black;
  }
  font-size: 0.8rem;
  font-weight: 500;
  overflow: hidden; /* Hide overflow text */
  text-overflow: ellipsis; /* Add ellipsis for overflow text */
  white-space: nowrap; /* Prevent wrapping to multiple lines */
  color: grey;
  max-width: calc(
    100% - 100px
  ); /* Adjust based on the amount width and padding */
}

.amount {
  font-size: 0.9rem;
  font-weight: 500;
  text-align: right;
}

.pagination {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.pagination button {
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: #fff;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.pagination button:hover {
  background-color: #0056b3;
}

.pagination button.disabled {
  background-color: #ddd;
  cursor: not-allowed;
}
.actionItem {
  display: flex;
  align-self: flex-end;
  align-items: center;
  justify-content: space-between;
  margin-top: 10px;
  .btn {
    padding: 2px 20px;
    border-radius: 2px;
    font-size: 13px;
    font-weight: 700;
    color: white;
  }
  .view {
    background-color: rgb(2, 0, 47);
    margin-right: 7px;
  }
  .edit {
    background-color: rgb(255, 196, 0);
  }
  .delete {
    background-color: red;
    justify-self: flex-end;
    margin-left: 10px;
  }
}
.emptyList {
  background-color: white;
  color: black;
  display: flex;
  padding: 5px;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}
.moreLoading {
  background-color: rgba(56, 111, 160, 0.5);
  width: 100%;
  color: white;
}
.descriptionBox {
  display: flex;
  .description {
    max-width: 55% !important;
    display: inline-block;
    word-break: normal;
    font-size: 0.7rem;
    margin-left: 2px;
  }
}
