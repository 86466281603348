.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modalContent {
  background-color: white;
  border-radius: 10px;
  padding: 20px;
  position: relative;
  max-width: 90%;
  max-height: 90%;
  overflow: hidden;
  text-align: center;
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.imageContainer {
  max-height: 70vh;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  }
}

.navigation {
  margin-top: 15px;
  display: flex;
  justify-content: space-between;

  button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:disabled {
      background-color: #b0c4de;
      cursor: not-allowed;
    }

    &:hover:not(:disabled) {
      background-color: #0056b3;
    }
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .modalContent {
    width: 90%;
    margin: 0px 10px;
    min-height: 50%;
    padding-top: 50px;
  }
}
