/* Mobile-first responsive design */
body {
  margin: 0;
  font-family: Arial, sans-serif;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
}

.login-card {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 400px;
  text-align: center;
}

h2 {
  margin-bottom: 20px;
  color: #333;
}

.input-group {
  margin-bottom: 20px;
  text-align: left;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
  color: #333;
}

.input-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

.error {
  color: red;
  font-size: 12px;
}

.login-btn {
  width: 100%;
  padding: 10px;

  background-color: rgb(251, 158, 71);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

.login-btn:hover {
  background-color: rgb(251, 158, 71);
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .login-card {
    width: 90%;
    padding: 15px;
  }

  .login-btn {
    font-size: 14px;
  }
}
