// color
// color
$primary: rgb(26, 67, 78);
$secondary: rgb(255, 255, 255);
$primaryDark: #0982c6;
$primaryLight: #56c4ff;
$light-green: #62b830;
$glass-green: #84b368c9;
$btnGreen: rgb(45, 155, 45);
$green: #3c701d;
$green-funcky: #098;
$skyBlue: #1ba7f2;
$skyBlueLight: #8cd7ff;
$themeWhite: white;
$shadow: rgba(0, 0, 0, 0.289);
$shadow-grey: #4d4a4a4f;
$shadow-lightgrey: #0f0f0f3a;
$brightRed: #d2042d;
$glass-red: #9e2039dc;
$athen-grey: #e7e8eb;
$black: #000000;
$black-shade-1: #00000029;
$black-shade-2: #00000026;
$blac-shade-3: #161616;
$yellow: rgb(253, 185, 19);
$secondary: #141e30;
$alice-blue: #f7f9fb;
$blue: #003f5c;
$catskill-white: #fbfcfd;
$coal-black: #2a323f;
$coral-pearl: #596372;
$dark-blue: #071023;
$dark-blue-magenta: #00000033;
$dark-grey: #92a3af;
$error-color: red;
$geyser: #dee2e6;
$gray: #888888;
$gray87: #dedede;
$grey: #7d868d;
$grey2: #c7d0d6;
$grey4: #e0e7ec;
$hawkes-blue: #dadce0;
$light-grey: #f2f1f3;
$midnight-blue: #0000004d;
$modal-header: #f0f2f3;
$pale-pink: #d54c7c;
$pink: #e45b8b;
$russian-black: #0000001a;
$salmon: #ff7070;
$white: #ffffff;
$base-color: #c6538c;

$laptop: "screen and (max-width: 1800px)";
$m-laptop: "screen and (max-width: 1500px)";
$s-laptop: "screen and (max-width: 1300px)";
$tablet: "screen and (max-width: 1124px)";
$tablet2: "screen and (max-width: 1024px)";
$pablet: "screen and (max-width: 700px)";
$mobile: "screen and (max-width: 500px)";
$m-mobile: "screen and (max-width: 420px)";
$s-mobile: "screen and (max-width: 370px)";
$xs-mobile: "screen and (max-width: 340px)";
